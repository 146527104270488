var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown__container",class:{ 'full-width': _vm.fullWidth },style:(_vm.customStyle)},[_c('div',{staticClass:"dropdown",class:{
      'is-active': _vm.isActive && !_vm.disabled,
      'full-width': _vm.fullWidth,
    },style:(_vm.customStyle),on:{"click":function($event){_vm.isActive = !_vm.isActive}}},[_c('div',{staticClass:"dropdown-trigger",class:{ 'full-width': _vm.fullWidth },style:(_vm.customStyle)},[_c('button',{staticClass:"button",class:{
          'full-width': _vm.fullWidth,
          'icon-spread': _vm.iconSpread,
          error: _vm.error,
          truncate: _vm.setWidth > 0,
        },style:(_vm.customStyle),attrs:{"type":"button","aria-haspopup":"true","aria-controls":"dropdown-menu","disabled":_vm.disabled}},[_c('span',[_vm._v(_vm._s(_vm.selected.text))]),_c('span',{staticClass:"icon is-small"},[_vm._t("icon",[_c('i',{staticClass:"arrow down"})])],2)])]),_c('div',{staticClass:"dropdown-menu",attrs:{"role":"menu","tabindex":"-1"}},[_c('div',{staticClass:"dropdown-content"},[_c('ul',_vm._l((_vm.options),function(data){return _c('li',{key:data.uniqueKey,class:[
              'dropdown-item',
              data.value === _vm.selected.value && 'is-active' ],on:{"click":function($event){return _vm.handleSelected(data)}}},[_vm._v(" "+_vm._s(data.text)+" ")])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }