import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { Client, ClientListResponse } from '@/models/swine/client/client.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { DEFAULT_SELECT_OPTION } from '@/utils/constants';
import { Region } from '@/models/swine/regions/region.model';
import { State } from '@/models/swine/states/state.model';
import { generateUniqueId } from '@/utils/lodash';

export interface PaginatedRequestParams extends FetchClientsRequestParams {
  limit: number;
  offset: number;
  sortBy: string;
}

export interface FetchClientsRequestParams {
  country: string;
  state: string;
  region?: string;
  startDate?: string;
  endDate?: string;
  species: string;
}

interface SearchRequestParams extends PaginatedRequestParams {
  search: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  name: 'client.list',
  store,
})
class ClientList extends VuexModule {
  paginationResponse: ClientListResponse | null = null;
  response: Client[] | null = null;
  isLoading = false;
  error: any | null = null;
  sortString = '';
  regionsList: DropdownOption[] = [];
  statesList: DropdownOption[] = [];

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: Client[]) {
    this.response = payload;
  }

  @Mutation
  setPaginationResponse(payload: ClientListResponse) {
    this.paginationResponse = payload;
  }

  @Mutation
  setSortString(sortString: string) {
    this.sortString = sortString;
  }

  @Mutation
  setRegionsList(payload: DropdownOption[]) {
    this.regionsList = payload;
  }

  @Mutation
  setStatesList(payload: DropdownOption[]) {
    this.statesList = payload;
  }

  @Action
  async getPaginatedClients(params: PaginatedRequestParams) {
    this.context.commit('updateLoadingStatus', true);
    try {
      const response = await axios.get<ClientListResponse>(Endpoints.Clients, {
        params,
      });
      if (response.data) {
        this.context.commit('setPaginationResponse', response.data);
      }
    } catch (error) {
      this.context.commit('setError', error.data);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async getClients(params: FetchClientsRequestParams) {
    this.context.commit('updateLoadingStatus', true);
    try {
      const response = await axios.get<ClientListResponse>(Endpoints.Clients, {
        params,
      });
      if (response.data) {
        this.context.commit('setResponse', response.data);
      }
    } catch (error) {
      this.context.commit('setError', error.data);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getRegions(request: string) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get(Endpoints.Regions, {
          params: {
            country: request,
          },
        })
        .then((response) => {
          if (response.data) {
            const parsedRegionDropdown: DropdownOption[] = [];
            response.data.map((region: Region) => {
              parsedRegionDropdown.push({
                text: region.name,
                value: String(region.id),
                uniqueKey: generateUniqueId(`${region.name}_`),
              });
            });

            this.context.commit('setRegionsList', [
              ...DEFAULT_SELECT_OPTION,
              ...parsedRegionDropdown,
            ]);
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getStates(request: string) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get(Endpoints.States, {
          params: {
            region: request,
          },
        })
        .then((response) => {
          if (response.data) {
            const parsedStatesDropdown: DropdownOption[] = [];
            response.data.map((state: State) => {
              parsedStatesDropdown.push({
                text: state.name,
                value: String(state.id),
                uniqueKey: generateUniqueId(`${state.name}_`),
              });
            });

            this.context.commit('setStatesList', [
              ...DEFAULT_SELECT_OPTION,
              ...parsedStatesDropdown,
            ]);
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async searchClients(params: SearchRequestParams) {
    this.context.commit('updateLoadingStatus', true);
    try {
      const response = await axios.get<ClientListResponse>(
        `${Endpoints.Clients}/search`,
        {
          params,
        },
      );
      if (response.data) {
        this.context.commit('setPaginationResponse', response.data);
      }
    } catch (error) {
      this.context.commit('setError', error.data);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ClientList);
