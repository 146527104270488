











































































import Page from '@/components/swine/layout/Page.vue';
import SearchBar from '@/components/swine/SearchBar.vue';
import Dropdown from '@/components/swine/Dropdown.vue';
import { ClientStores } from '@/store/swine/client/index';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  DEFAULT_COUNT_PER_PAGE,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_SELECT_OPTION,
} from '@/utils/constants';
import { Client, RawExcelClientData } from '@/models/swine/client/client.model';
import {
  RowCountChange,
  PageNumberChange,
} from '@/models/swine/pagination.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import ClientTable from './ClientTable.vue';
import userSessionDetails from '@/store/swine/user-session/user-session.details';
import { Role } from '@/utils/constants';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { exportClientExcel } from '@/utils/export-excel/export-excel';
import { CountryStores } from '@/store/swine/country';
import { generateUniqueId } from '@/utils/lodash';
import { ProfileStores } from '@/store/swine/profile';
@Component({
  components: {
    Page,
    SearchBar,
    Dropdown,
    ClientTable,
  },
})
export default class ClientManagement extends Vue {
  store = ClientStores.list;
  clientList: Client[] = [];
  totalClients = 0;
  filteredClients = 0;
  countries: DropdownOption[] = [];
  regions = DEFAULT_SELECT_OPTION;
  states = DEFAULT_SELECT_OPTION;
  currentPageNumber = DEFAULT_PAGE_NUMBER;
  currentRowsPerPage = DEFAULT_COUNT_PER_PAGE;
  offsetValue = 0;
  countryFilter = '';
  regionFilter = '';
  stateFilter = '';
  search = '';
  sortBy = '';
  isCurrentRoleRegional = false;
  isCurrentRoleViewer = false;
  userSessionStore = userSessionDetails;
  countryStore = CountryStores.list;
  isRegionFilterDisabled = false;
  userProfileStore = ProfileStores.profile;

  mounted() {
    this.countryStore.getAllCountries();
    this.retrieveClients();
    this.updatePermissions();
  }

  @Watch(`countryStore.response`)
  updateCountries() {
    if (this.countryStore.response) {
      this.countries = [];
      this.countries.push({
        text: 'All',
        value: '',
        uniqueKey: `all`,
      });
      this.countryStore.response.map((data) => {
        this.countries.push({
          text: data.code,
          value: String(data.id),
          uniqueKey: generateUniqueId(`${data.code}_`),
        });
      });
    }
  }

  updatePermissions() {
    if (
      this.userProfileStore.userProfile &&
      this.userProfileStore.userProfile.role === Role.Regional
    ) {
      this.isCurrentRoleRegional = true;
    } else {
      this.isCurrentRoleRegional = false;
    }

    if (
      this.userProfileStore.userProfile &&
      this.userProfileStore.userProfile.role === Role.Viewer
    ) {
      this.isCurrentRoleViewer = true;
    } else {
      this.isCurrentRoleViewer = false;
    }
  }

  @Watch('store.paginationResponse')
  updateInformation() {
    if (this.store.paginationResponse) {
      this.clientList = this.store.paginationResponse.clients;
      this.totalClients = this.store.paginationResponse.totalCount;
      this.filteredClients = this.store.paginationResponse.pagination.totalRecords ?? 0;
    }
  }

  @Watch('store.regionsList')
  updateRegionsFilterOptions() {
    if (this.store.regionsList) {
      this.regions = this.store.regionsList;
      this.isRegionFilterDisabled = false;

      const notAvailableIndex = this.regions.findIndex((data) => {
        return data.text === 'N/A';
      });
      // If Region is N/A
      if (notAvailableIndex > -1) {
        this.isRegionFilterDisabled = true;
        this.changeRegionFilter(this.regions[notAvailableIndex]);
      }
    }
  }

  @Watch('store.statesList')
  updateStatesFilterOptions() {
    if (this.store.statesList) {
      this.states = this.store.statesList;
    }
  }

  changeCountryFilter(params: DropdownOption) {
    this.resetPagination();
    this.countryFilter = params.value;

    if (params.value) {
      this.store.getRegions(this.countryFilter);
      this.store.setStatesList([...DEFAULT_SELECT_OPTION]);
    } else {
      this.store.setRegionsList([...DEFAULT_SELECT_OPTION]);
      this.store.setStatesList([...DEFAULT_SELECT_OPTION]);
    }

    this.regionFilter = '';
    this.stateFilter = '';
    this.retrieveClients();
  }

  changeRegionFilter(params: DropdownOption) {
    this.resetPagination();
    this.regionFilter = params.value;

    if (params.value) {
      this.store.getStates(this.regionFilter);
    } else {
      this.store.setStatesList([...DEFAULT_SELECT_OPTION]);
    }

    this.stateFilter = '';
    this.retrieveClients();
  }

  changeStateFilter(params: DropdownOption) {
    this.resetPagination();
    this.stateFilter = params.value;
    this.retrieveClients();
  }

  searchReports(searchQuery: string) {
    this.search = searchQuery;
    this.retrieveClients();
  }

  sortTable(sort: string) {
    this.sortBy = sort;
    this.retrieveClients();
  }

  navigateToCreate() {
    this.$router.push({
      name: 'CreateClient',
    });
  }

  updateRowCount(params: RowCountChange) {
    this.currentRowsPerPage = params.rowsPerPage;
    this.currentPageNumber = 1; // Reset it to prevent issues where we search from 21-10 (example)
    this.offsetValue = params.offsetValue;
    this.retrieveClients();
  }

  updatePageNumber(params: PageNumberChange) {
    this.currentPageNumber = params.pageNumber;
    this.offsetValue = params.offsetValue;
    this.retrieveClients();
  }

  retrieveClients() {
    const species = this.$router.currentRoute.fullPath.split('/')[1];

    const baseSearchParams = {
      limit: this.currentRowsPerPage,
      offset: this.offsetValue,
      country: this.countryFilter,
      region: this.regionFilter,
      state: this.stateFilter,
      sortBy: this.sortBy,
      species,
    };

    if (this.search === '') {
      this.store.getPaginatedClients(baseSearchParams);
    } else {
      this.store.searchClients({
        ...baseSearchParams,
        search: this.search,
      });
    }
  }

  resetPagination() {
    this.currentPageNumber = 1; // Reset it to prevent issues where we search from 21-10 (example)
    this.offsetValue = 0;
  }

  async exportExcel() {
    // Get the parse data from the API
    const { data } = await axios.get<RawExcelClientData[]>(
      `${Endpoints.Clients}/excel`
    );
    exportClientExcel(data);
  }
}
