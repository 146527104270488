

























































































import { Vue, Component } from 'vue-property-decorator';
import TableLayout from '@/components/swine/layout/TableLayout.vue';
import Pagination from '@/components/swine/Pagination.vue';
import { Emit, Prop } from 'vue-property-decorator';
import { Client } from '@/models/swine/client/client.model';
import {
  RowCountChange,
  PageNumberChange,
} from '@/models/swine/pagination.model';
import { Farm } from '@/models/swine/farm/farm.model';

@Component({
  components: {
    TableLayout,
    Pagination,
  },
})
export default class ClientTable extends Vue {
  @Prop({
    type: Array,
    default: [],
  })
  clientList!: Client[];

  @Prop({
    type: Number,
    default: 0,
  })
  totalCount!: number;

  @Prop({
    type: Number,
    default: 1,
  })
  pageNumber!: number;

  @Prop({
    type: Number,
    default: 10,
  })
  rowsPerPage!: number;

  viewClientSummary(id: number) {
    this.$router.push({
      name: 'ClientSummary',
      params: {
        clientId: String(id),
      },
    });
  }

  @Emit('sort')
  sortPressed(field: string, order: 'asc' | 'desc') {
    return `${field}:${order}`;
  }

  @Emit('rowCountChange')
  updateRowCount(params: RowCountChange) {
    return params;
  }

  @Emit('pageNumberChange')
  updatePageNumber(params: PageNumberChange) {
    return params;
  }

  getAuditReportCount(farm: Farm[]) {
    const result = farm.map((farm) => {
      const total = farm.farmUnits.reduce((sum, farmUnit) => {
        return sum + farmUnit.auditReportCount;
      }, 0);
      return total;
    });
    return result.reduce((sum, val) => sum + val, 0);
  }
}
