import {
  ExcelReportData,
  ExportSummaryReportData,
} from "@/models/swine/report/report.model";
import { RawExcelClientData } from "@/models/swine/client/client.model";
import {
  convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp,
  convertDateToLocalDMMMMYYYYWithoutTimeStamp,
} from "../moment";

/*
eslint-disable @typescript-eslint/no-var-requires
*/
const ExcelJS = require("exceljs");
const saveAs = require("file-saver");

export async function exportReportExcel(
  reports: ExcelReportData[],
  isMockReport: boolean
) {
  const excelReportList: any[] = [];
  reports.map((report) => {
    const excelFileRawData = new ExcelJS.Workbook();
    const excelSheetRawData = excelFileRawData.addWorksheet(
      "Lung Lesion Score Raw Data"
    );
    excelSheetRawData.columns = [
      { header: "MOCK/NORMAL", key: "mockNormal", width: 20 },
      { header: "REPORT NAME", key: "reportName", width: 26 },
      { header: "MONTH", key: "month", width: 26.5 },
      { header: "YEAR", key: "year", width: 10 },
      { header: "COUNTRY", key: "country", width: 14 },
      { header: "REGION", key: "region", width: 20 },
      { header: "STATE/PROVINCE", key: "stateProvince", width: 16 },
      { header: "CLIENT", key: "client", width: 20 },
      { header: "FARM", key: "farm", width: 20 },
      { header: "FARM UNIT", key: "farmUnit", width: 17 },
      { header: "Weaning Date", key: "weaningDate", width: 25 },
      { header: "Market Date", key: "marketDate", width: 25 },
      {
        header: "Average Weaning Weight",
        key: "averageWeaningWeight",
        width: 20,
      },
      {
        header: "Average Market Weight",
        key: "averageMarketWeight",
        width: 21,
      },
      { header: "FCR", key: "fcr", width: 16 },
      { header: "Gen Remarks", key: "genRemarks", width: 25 },
      { header: "RA", key: "ra", width: 7 },
      { header: "RC", key: "rc", width: 7 },
      { header: "RD", key: "rd", width: 7 },
      { header: "LA", key: "la", width: 7 },
      { header: "LC", key: "lc", width: 7 },
      { header: "LD", key: "ld", width: 7 },
      { header: "ACC", key: "acc", width: 7 },
      {
        header: "BRONCHOPNEUMONIA SCORE",
        key: "bronchopneumoniaScore",
        width: 28,
      },
      { header: "P0", key: "p0", width: 16 },
      { header: "P1", key: "p1", width: 16 },
      { header: "P2", key: "p2", width: 16 },
      { header: "P3", key: "p3", width: 16 },
      { header: "P4", key: "p4", width: 16 },
      { header: "ABSCESS", key: "abscess", width: 20 },
      { header: "SCARRING", key: "scarring", width: 20 },
      { header: "PERICARDITIS", key: "pericarditis", width: 34 },
      {
        header: "ASSESSMENT GENERAL REMARKS",
        key: "assessmentGeneralRemarks",
        width: 36,
      },
    ];

    let reportCodeName = "";
    if (
      report &&
      Object.keys(report).length !== 0 &&
      Object.values(report) &&
      report.report &&
      Object.keys(report.report).length !== 0 &&
      Object.values(report.report) &&
      report.report.report_values &&
      Object.keys(report.report.report_values).length !== 0 &&
      Object.values(report.report.report_values) &&
      report.report.report_values.farmUnit &&
      Object.keys(report.report.report_values.farmUnit).length !== 0 &&
      Object.values(report.report.report_values.farmUnit) &&
      report.report.report_values.farmUnit.farmUnitCodeName
    ) {
      reportCodeName = report.report.report_values.farmUnit.farmUnitCodeName;
    } else if (
      report &&
      Object.keys(report).length !== 0 &&
      Object.values(report) &&
      report.report &&
      Object.keys(report.report).length !== 0 &&
      Object.values(report.report) &&
      report.report.report_values &&
      Object.keys(report.report.report_values).length !== 0 &&
      Object.values(report.report.report_values) &&
      report.report.report_values.reportName
    ) {
      reportCodeName = report.report.report_values.reportName;
    }

    let dates: string[] = [];
    if (
      report &&
      Object.keys(report).length !== 0 &&
      Object.values(report) &&
      report.report &&
      Object.keys(report.report).length !== 0 &&
      Object.values(report.report) &&
      report.report.published_date
    ) {
      dates = convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
        report.report.published_date
      )
        .toString()
        .split("/");
    }

    const monthsDict: { [key: string]: string } = {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December",
    };

    let reportValues: any; //type is any from ExcelReportData interface
    if (
      report &&
      Object.keys(report).length !== 0 &&
      Object.values(report) &&
      report.report &&
      Object.keys(report.report).length !== 0 &&
      Object.values(report.report) &&
      report.report.report_values &&
      Object.keys(report.report.report_values).length !== 0 &&
      Object.values(report.report.report_values)
    ) {
      reportValues = report.report.report_values;
    } else {
      reportValues = "-";
    }
    let farmUnit: any;
    if (
      report &&
      Object.keys(report).length !== 0 &&
      Object.values(report) &&
      report.report &&
      Object.keys(report.report).length !== 0 &&
      Object.values(report.report) &&
      report.report.report_values &&
      Object.keys(report.report.report_values).length !== 0 &&
      Object.values(report.report.report_values) &&
      report.report.report_values.farmUnit &&
      Object.keys(report.report.report_values.farmUnit).length !== 0 &&
      Object.values(report.report.report_values.farmUnit)
    ) {
      farmUnit = report.report.report_values.farmUnit;
    }

    let reportTotalNumberOfLungs: any;
    if (
      report &&
      Object.keys(report).length !== 0 &&
      Object.values(report) &&
      report.report &&
      Object.keys(report.report).length !== 0 &&
      Object.values(report.report) &&
      report.report.report_values &&
      Object.keys(report.report.report_values).length !== 0 &&
      Object.values(report.report.report_values) &&
      report.report.report_values.totalNumberOfLungs &&
      report.report.report_values.totalNumberOfLungs !== []
    ) {
      reportTotalNumberOfLungs = report.report.report_values.totalNumberOfLungs;
    } else {
      reportTotalNumberOfLungs = [];
    }
    if (reportTotalNumberOfLungs.length > 0) {
      for (
        let lungNumber = 0;
        lungNumber <= reportTotalNumberOfLungs.length - 1;
        lungNumber++
      ) {
        const lungReport =
          report.report.report_values.totalNumberOfLungs[lungNumber]; //Definitely will have 1 lung at least cause passed if test
        let lungReportBromchopneumoniaScoreTotal;
        if (
          lungReport &&
          lungReport.bronchopneumoniaScore !== undefined &&
          lungReport.bronchopneumoniaScore &&
          Object.keys(lungReport.bronchopneumoniaScore).length !== 0 &&
          Object.values(lungReport.bronchopneumoniaScore)
        ) {
          lungReportBromchopneumoniaScoreTotal =
            lungReport.bronchopneumoniaScore.rightApical +
            lungReport.bronchopneumoniaScore.rightCardiac +
            lungReport.bronchopneumoniaScore.rightDiaphragmatic +
            lungReport.bronchopneumoniaScore.leftApical +
            lungReport.bronchopneumoniaScore.leftCardiac +
            lungReport.bronchopneumoniaScore.leftDiaphragmatic +
            lungReport.bronchopneumoniaScore.accessory;
        } else {
          lungReportBromchopneumoniaScoreTotal = "-";
        }
        excelSheetRawData.addRow({
          mockNormal: isMockReport ? "Mock" : "Normal",
          reportName: reportCodeName !== "" ? reportCodeName : "-",
          month: dates !== [] ? (dates[1] ? monthsDict[dates[1]] : "-") : "-", //dates[1] !== null
          year: dates !== [] ? (dates[2] ? dates[2] : "-") : "-", //dates[2] !== null
          country:
            report.country &&
            Object.keys(report.country).length !== 0 &&
            Object.values(report.country)
              ? report.country.name
                ? report.country.name //!== null
                  ? report.country.name
                  : "-"
                : "-"
              : "-",
          region:
            report.region &&
            Object.keys(report.region).length !== 0 &&
            Object.values(report.region)
              ? report.region.name //!== null
                ? report.region.name === "N/A"
                  ? "N/A"
                  : report.region.name
                : "-"
              : "-",
          stateProvince:
            report.state &&
            Object.keys(report.state).length !== 0 &&
            Object.values(report.state)
              ? report.state.name
                ? report.state.name //!== null
                  ? report.state.name
                  : "-"
                : "-"
              : "-",
          client:
            report.client &&
            Object.keys(report.client).length !== 0 &&
            Object.values(report.client)
              ? report.client.name
                ? report.client.name //!== null
                  ? report.client.name
                  : "-"
                : "-"
              : "-",
          farm:
            report.farm &&
            Object.keys(report.farm).length !== 0 &&
            Object.values(report.farm)
              ? report.farm.name
                ? report.farm.name //!== null
                  ? report.farm.name
                  : "-"
                : "-"
              : "-",
          farmUnit:
            report.report &&
            Object.keys(report.report).length !== 0 &&
            Object.values(report.report)
              ? reportValues !== "-"
                ? reportValues.farmUnit &&
                  Object.keys(reportValues.farmUnit).length !== 0 &&
                  Object.values(reportValues.farmUnit)
                  ? reportValues.farmUnit.farmUnitCodeName
                    ? farmUnit.farmUnitCodeName.split("-")[5]
                    : "-"
                  : "-"
                : "-"
              : "-",
          weaningDate:
            report.report &&
            Object.keys(report.report).length !== 0 &&
            Object.values(report.report)
              ? reportValues !== "-"
                ? reportValues.weaningDate //!== null
                  ? convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                      reportValues.weaningDate
                    )
                  : "-"
                : "-"
              : "-",
          marketDate:
            report.report &&
            Object.keys(report.report).length !== 0 &&
            Object.values(report.report)
              ? reportValues !== "-"
                ? reportValues.marketDate //!== null
                  ? convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                      reportValues.marketDate
                    )
                  : "-"
                : "-"
              : "-",
          averageWeaningWeight:
            report.report &&
            Object.keys(report.report).length !== 0 &&
            Object.values(report.report)
              ? reportValues !== "-"
                ? reportValues.averageWeaningWT ||
                  reportValues.averageWeaningWT === 0
                  ? reportValues.averageWeaningWT
                  : "-"
                : "-"
              : "-",
          averageMarketWeight:
            report.report &&
            Object.keys(report.report).length !== 0 &&
            Object.values(report.report)
              ? reportValues !== "-"
                ? reportValues.averageMarketWT ||
                  reportValues.averageMarketWT === 0
                  ? reportValues.averageMarketWT
                  : "-"
                : "-"
              : "-",
          fcr:
            report.report &&
            Object.keys(report.report).length !== 0 &&
            Object.values(report.report)
              ? reportValues !== "-"
                ? reportValues.fcr || reportValues.fcr === 0
                  ? reportValues.fcr
                  : "-"
                : "-"
              : "-",
          genRemarks:
            report.report &&
            Object.keys(report.report).length !== 0 &&
            Object.values(report.report)
              ? reportValues !== "-"
                ? reportValues.generalRemarks &&
                  Object.keys(reportValues.generalRemarks).length !== 0 &&
                  Object.values(reportValues.generalRemarks)
                  ? reportValues.generalRemarks.comment
                    ? reportValues.generalRemarks.comment
                    : "-"
                  : "-"
                : "-"
              : "-",
          ra: lungReport
            ? lungReport.bronchopneumoniaScore &&
              Object.keys(lungReport.bronchopneumoniaScore).length !== 0
              ? lungReport.bronchopneumoniaScore.rightApical ||
                lungReport.bronchopneumoniaScore.rightApical === 0
                ? lungReport.bronchopneumoniaScore.rightApical
                : "-"
              : "-"
            : "-",
          rc: lungReport
            ? lungReport.bronchopneumoniaScore &&
              Object.keys(lungReport.bronchopneumoniaScore).length !== 0
              ? lungReport.bronchopneumoniaScore.rightCardiac ||
                lungReport.bronchopneumoniaScore.rightCardiac === 0
                ? lungReport.bronchopneumoniaScore.rightCardiac
                : "-"
              : "-"
            : "-",
          rd: lungReport
            ? lungReport.bronchopneumoniaScore &&
              Object.keys(lungReport.bronchopneumoniaScore).length !== 0
              ? lungReport.bronchopneumoniaScore.rightDiaphragmatic ||
                lungReport.bronchopneumoniaScore.rightDiaphragmatic === 0
                ? lungReport.bronchopneumoniaScore.rightDiaphragmatic
                : "-"
              : "-"
            : "-",
          la: lungReport
            ? lungReport.bronchopneumoniaScore &&
              Object.keys(lungReport.bronchopneumoniaScore).length !== 0
              ? lungReport.bronchopneumoniaScore.leftApical ||
                lungReport.bronchopneumoniaScore.leftApical === 0
                ? lungReport.bronchopneumoniaScore.leftApical
                : "-"
              : "-"
            : "-",
          lc: lungReport
            ? lungReport.bronchopneumoniaScore &&
              Object.keys(lungReport.bronchopneumoniaScore).length !== 0
              ? lungReport.bronchopneumoniaScore.leftCardiac ||
                lungReport.bronchopneumoniaScore.leftCardiac === 0
                ? lungReport.bronchopneumoniaScore.leftCardiac
                : "-"
              : "-"
            : "-",
          ld: lungReport
            ? lungReport.bronchopneumoniaScore &&
              Object.keys(lungReport.bronchopneumoniaScore).length !== 0
              ? lungReport.bronchopneumoniaScore.leftDiaphragmatic ||
                lungReport.bronchopneumoniaScore.leftDiaphragmatic === 0
                ? lungReport.bronchopneumoniaScore.leftDiaphragmatic
                : "-"
              : "-"
            : "-",
          acc: lungReport
            ? lungReport.bronchopneumoniaScore &&
              Object.keys(lungReport.bronchopneumoniaScore).length !== 0
              ? lungReport.bronchopneumoniaScore.accessory ||
                lungReport.bronchopneumoniaScore.accessory === 0
                ? lungReport.bronchopneumoniaScore.accessory
                : "-"
              : "-"
            : "-",
          bronchopneumoniaScore:
            lungReportBromchopneumoniaScoreTotal ||
            lungReportBromchopneumoniaScoreTotal === 0
              ? lungReportBromchopneumoniaScoreTotal
              : "-",
          p0:
            lungReport &&
            lungReport.pleuritis &&
            Object.keys(lungReport.pleuritis).length !== 0
              ? lungReport.pleuritis.p1 === null
                ? lungReport.pleuritis.spesGrade === 0
                  ? 1
                  : "-"
                : lungReport.pleuritis.p0 !== undefined
                ? lungReport.pleuritis.p0
                : "-"
              : "-",
          p1:
            lungReport &&
            lungReport.pleuritis &&
            Object.keys(lungReport.pleuritis).length !== 0
              ? lungReport.pleuritis.p1 === null
                ? lungReport.pleuritis.spesGrade === 1
                  ? 1
                  : "-"
                : lungReport.pleuritis.p1 === 1
                ? 1
                : lungReport.pleuritis.p1 !== undefined
                ? lungReport.pleuritis.p1
                : "-"
              : "-",
          p2:
            lungReport &&
            lungReport.pleuritis &&
            Object.keys(lungReport.pleuritis).length !== 0
              ? (lungReport.pleuritis.p1 === 1 &&
                  lungReport.pleuritis.spesGrade === 2) ||
                (lungReport.pleuritis.p1 === null &&
                  lungReport.pleuritis.spesGrade === 2)
                ? 1
                : lungReport.pleuritis.p1 === null
                ? "-"
                : lungReport.pleuritis.p2 !== undefined
                ? lungReport.pleuritis.p2
                : "-"
              : "-",
          p3:
            lungReport &&
            lungReport.pleuritis &&
            Object.keys(lungReport.pleuritis).length !== 0
              ? (lungReport.pleuritis.p1 === 1 &&
                  lungReport.pleuritis.spesGrade === 3) ||
                (lungReport.pleuritis.p1 === null &&
                  lungReport.pleuritis.spesGrade === 3)
                ? 1
                : lungReport.pleuritis.p1 === null
                ? "-"
                : lungReport.pleuritis.p3 !== undefined
                ? lungReport.pleuritis.p3
                : "-"
              : "-",
          p4:
            lungReport &&
            lungReport.pleuritis &&
            Object.keys(lungReport.pleuritis).length !== 0
              ? (lungReport.pleuritis.p1 === 1 &&
                  lungReport.pleuritis.spesGrade === 4) ||
                (lungReport.pleuritis.p1 === null &&
                  lungReport.pleuritis.spesGrade === 4)
                ? 1
                : lungReport.pleuritis.p1 === null
                ? "-"
                : lungReport.pleuritis.p4 !== undefined
                ? lungReport.pleuritis.p4
                : "-"
              : "-",

          abscess: lungReport
            ? lungReport.lungDiseases &&
              Object.keys(lungReport.lungDiseases).length !== 0
              ? lungReport.lungDiseases.abscess ||
                lungReport.lungDiseases.abscess === 0
                ? lungReport.lungDiseases.abscess
                : "-"
              : "-"
            : "-",
          scarring: lungReport
            ? lungReport.lungDiseases &&
              Object.keys(lungReport.lungDiseases).length !== 0
              ? lungReport.lungDiseases.scarring ||
                lungReport.lungDiseases.scarring === 0
                ? lungReport.lungDiseases.scarring
                : "-"
              : "-"
            : "-",
          pericarditis: lungReport
            ? lungReport.lungDiseases &&
              Object.keys(lungReport.lungDiseases).length !== 0
              ? lungReport.lungDiseases.pericarditis ||
                lungReport.lungDiseases.pericarditis === 0
                ? lungReport.lungDiseases.pericarditis
                : "-"
              : "-"
            : "-",
          assessmentGeneralRemarks: lungReport
            ? lungReport.assessmentGeneralRemarks &&
              Object.keys(lungReport.assessmentGeneralRemarks).length !== 0
              ? lungReport.assessmentGeneralRemarks.comment ||
                lungReport.assessmentGeneralRemarks.comment === 0
                ? lungReport.assessmentGeneralRemarks.comment
                : "-"
              : "-"
            : "-",
        });
      }
    }
    // Set cell borders
    excelSheetRawData.columns.forEach((column: any) => {
      column.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Set text alignment
    excelSheetRawData.columns.forEach((column: any) => {
      column.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    // Set header font colour and text alignment
    const headerCells = [
      "A1",
      "B1",
      "C1",
      "D1",
      "E1",
      "F1",
      "G1",
      "H1",
      "I1",
      "J1",
      "K1",
      "L1",
      "M1",
      "N1",
      "O1",
      "P1",
      "Q1",
      "R1",
      "S1",
      "T1",
      "U1",
      "V1",
      "W1",
      "X1",
      "Y1",
      "Z1",
      "AA1",
      "AB1",
      "AC1",
      "AD1",
      "AE1",
      "AF1",
      "AG1",
      "AH1",
    ];
    headerCells.map((key) => {
      excelSheetRawData.getCell(key).font = {
        color: { argb: "FFFFFF" },
        bold: true,
      };
      excelSheetRawData.getCell(key).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    // Set orange header cell colours
    const orangeHeaderCells = [
      "A1",
      "B1",
      "C1",
      "D1",
      "E1",
      "F1",
      "G1",
      "H1",
      "I1",
      "J1",
    ];
    orangeHeaderCells.map((key) => {
      excelSheetRawData.getCell(key).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F38F1C" },
        bgColor: { argb: "F38F1C" },
      };
    });

    // Set green header cell colours
    const greenHeaderCells = ["K1", "L1", "M1", "N1", "O1", "P1", "Q1"];
    greenHeaderCells.map((key) => {
      excelSheetRawData.getCell(key).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "30BA50" },
        bgColor: { argb: "30BA50" },
      };
    });

    // Set blue header cell colours
    const blueHeaderCells = [
      "R1",
      "S1",
      "T1",
      "U1",
      "V1",
      "W1",
      "X1",
      "Y1",
      "Z1",
      "AA1",
      "AB1",
      "AC1",
      "AD1",
      "AE1",
      "AF1",
      "AG1",
      "AH1",
    ];
    blueHeaderCells.map((key) => {
      excelSheetRawData.getCell(key).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "2F3DC3" },
        bgColor: { argb: "2F3DC3" },
      };
    });

    // Insert each produced excel workbook with data into the list
    excelReportList.push(excelFileRawData);
  });
  for (const excelReport of excelReportList) {
    const buffer = await excelReport.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";
    const blob = new Blob([buffer], { type: fileType });
    let publishedAtDateOnly = "";
    if (
      reports[excelReportList.indexOf(excelReport)] &&
      Object.keys(reports[excelReportList.indexOf(excelReport)]).length !== 0 &&
      Object.values(reports[excelReportList.indexOf(excelReport)]) &&
      reports[excelReportList.indexOf(excelReport)].report &&
      Object.keys(reports[excelReportList.indexOf(excelReport)]).length !== 0 &&
      reports[excelReportList.indexOf(excelReport)].report.published_date !==
        null
    ) {
      const dateToLocal =
        reports[excelReportList.indexOf(excelReport)].report.published_date;
      const dateToLocalString = convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
        dateToLocal
      ).toString();
      publishedAtDateOnly = dateToLocalString;
    } else {
      publishedAtDateOnly = "NO_DATE_AVAILABLE";
    }

    saveAs(
      blob,
      `${isMockReport ? "MOCK" : "NORMAL"}_${
        reports[excelReportList.indexOf(excelReport)]
          ? reports[excelReportList.indexOf(excelReport)].report
            ? reports[excelReportList.indexOf(excelReport)].report.id
              ? reports[excelReportList.indexOf(excelReport)].report.id
              : "No_Report_ID"
            : "No_Report"
          : "No_Report"
      }_${publishedAtDateOnly}` + fileExtension
    );
  }
}

export async function exportClientExcel(clients: RawExcelClientData[]) {
  // Set up a new ExcelJS workbook and worksheet
  const newExcelWorkbook = new ExcelJS.Workbook();
  const newClientExcelSheet = newExcelWorkbook.addWorksheet("Clients");

  // Set up Excel sheet columns
  newClientExcelSheet.columns = [
    { header: "NAME", key: "clientName", width: 20 },
    { header: "CLIENT CODE", key: "clientCode", width: 20 },
    { header: "CLIENT ADDRESS", key: "addressLine1", width: 20 },
    { header: "FARM NAME", key: "farmName", width: 20 },
    { header: "FARM CODE", key: "farmCode", width: 20 },
    { header: "FARM UNIT CODE", key: "farmUnitCode", width: 20 },
  ];

  clients.forEach((data) => {
    newClientExcelSheet.addRow({
      ...data,
      addressLine1: data.addressLine1 ? data.addressLine1 : "-",
    });
  });

  newClientExcelSheet.columns.forEach((column: any) => {
    column.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    column.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
  });

  const buffer = await newExcelWorkbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, `Clients.xlsx`);
}

export async function exportSummaryReportExcel(
  reports: ExportSummaryReportData[],
  isMockReport: boolean
) {
  // Excel Template
  const excelFileRawData = new ExcelJS.Workbook();
  const excelSheetRawData = excelFileRawData.addWorksheet(
    "Lung Lesion Score Raw Data"
  );
  excelSheetRawData.columns = [
    { header: "MOCK/NORMAL", key: "mockNormal", width: 20 },
    { header: "REPORT NAME", key: "reportName", width: 26 },
    { header: "DATE", key: "date", width: 26.5 },
    { header: "COUNTRY", key: "country", width: 14 },
    { header: "REGION", key: "region", width: 20 },
    { header: "STATE/PROVINCE", key: "stateProvince", width: 16 },
    { header: "CLIENT", key: "client", width: 20 },
    { header: "FARM", key: "farm", width: 20 },
    { header: "FARM UNIT", key: "farmUnit", width: 17 },
    { header: "Source of Animal", key: "animalTypes", width: 25 },
    { header: "Weaning Date", key: "weaningDate", width: 25 },
    { header: "Market Date", key: "marketDate", width: 25 },
    {
      header: "Average Weaning Weight",
      key: "averageWeaningWeight",
      width: 20,
    },
    {
      header: "Average Market Weight",
      key: "averageMarketWeight",
      width: 21,
    },
    { header: "ADG (kg)", key: "adg", width: 14 },
    { header: "FCR", key: "fcr", width: 16 },
    { header: "MARKET AGE(days)", key: "marketAgeDays", width: 20 },
    { header: "SEASON", key: "season", width: 16 },
    { header: "TYPE OF HOUSING", key: "housingType", width: 40 },
    { header: "TYPE OF FLOORING", key: "flooringType", width: 25 },
    { header: "TYPE OF OPERATION", key: "operationType", width: 25 },
    {
      header: "EST. MEDICATION COST (USD)",
      key: "estMedicationCost",
      width: 25,
    },
    { header: "M. HYO VACCINE", key: "mHyoVaccine", width: 16 },
    { header: "APP VACCINE", key: "appVaccine", width: 16 },
    { header: "PRRS VACCINE", key: "prrsVaccine", width: 16 },
    { header: "PCV2 VACCINE", key: "pcv2Vaccine", width: 16 },
    { header: "Gen Remarks", key: "genRemarks", width: 25 },
    { header: "Total Lungs Examined", key: "totalLungsExamined", width: 25 },
    {
      header: "Pneumonia Prevalence (%)",
      key: "pneumoniaPrevalence",
      width: 25,
    },
    {
      header: "Bronchopneumonic Lungs (%)",
      key: "bronchopneumonicLungs",
      width: 25,
    },
    { header: "Pneumonia Index", key: "pneumoniaIndex", width: 20 },
    {
      header: "Pleurisy Prevalence (%)",
      key: "pleurisyPrevalence",
      width: 25,
    },
    { header: "Cranial Pleurisy (%)", key: "cranialPleurisy", width: 25 },
    {
      header: "Dorsocaudal Pleurisy (%)",
      key: "dorsocaudalPleurisy",
      width: 25,
    },
    {
      header: "Dorsal Pleurisy Index",
      key: "dorsalPleurisyIndex",
      width: 25,
    },
    { header: "Abscess (%)", key: "abscess", width: 20 },
    { header: "Scarring/ Chronic (%)", key: "scarringChronic", width: 25 },
    { header: "Pericarditis (%)", key: "pericarditis", width: 20 },
  ];

  // Set cell borders
  excelSheetRawData.columns.forEach((column: any) => {
    column.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  // Set text alignment
  excelSheetRawData.columns.forEach((column: any) => {
    column.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
  });

  // Set header font colour and text alignment
  const headerCells = [
    "A1",
    "B1",
    "C1",
    "D1",
    "E1",
    "F1",
    "G1",
    "H1",
    "I1",
    "J1",
    "K1",
    "L1",
    "M1",
    "N1",
    "O1",
    "P1",
    "Q1",
    "R1",
    "S1",
    "T1",
    "U1",
    "V1",
    "W1",
    "X1",
    "Y1",
    "Z1",
    "AA1",
    "AB1",
    "AC1",
    "AD1",
    "AE1",
    "AF1",
    "AG1",
    "AH1",
    "AI1",
    "AJ1",
    "AK1",
    "AL1",
  ];
  headerCells.map((key) => {
    excelSheetRawData.getCell(key).font = {
      color: { argb: "FFFFFF" },
      bold: true,
    };
    excelSheetRawData.getCell(key).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
  });

  // Set orange header cell colours
  const orangeHeaderCells = [
    "A1",
    "B1",
    "C1",
    "D1",
    "E1",
    "F1",
    "G1",
    "H1",
    "I1",
  ];
  orangeHeaderCells.map((key) => {
    excelSheetRawData.getCell(key).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "F38F1C" },
      bgColor: { argb: "F38F1C" },
    };
  });

  // Set green header cell colours
  const greenHeaderCells = [
    "J1",
    "K1",
    "L1",
    "M1",
    "N1",
    "O1",
    "P1",
    "Q1",
    "R1",
    "S1",
    "T1",
    "U1",
    "V1",
    "W1",
    "X1",
    "Y1",
    "Z1",
    "AA1",
  ];
  greenHeaderCells.map((key) => {
    excelSheetRawData.getCell(key).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "30BA50" },
      bgColor: { argb: "30BA50" },
    };
  });

  // Set blue header cell colours
  const blueHeaderCells = [
    "AB1",
    "AC1",
    "AD1",
    "AE1",
    "AF1",
    "AG1",
    "AH1",
    "AI1",
    "AJ1",
    "AK1",
    "AL1",
  ];
  blueHeaderCells.map((key) => {
    excelSheetRawData.getCell(key).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "5A80B8" },
      bgColor: { argb: "5A80B8" },
    };
  });

  reports.map((reportData) => {
    const pleurisy = reportData.pleurisy;
    // Grabbed the index 0 Report as the data returned is an array even though if one ID is passed
    // into the function, as the query will select where 'in', thus use the index 0 for now.
    const report = reportData.report[0];
    const summaryReport = reportData.summaryReport;
    let reportCodeName = "";
    let reportValue: any = "";
    if (report && Object.keys(report).length !== 0 && Object.values(report)) {
      if (report.report) {
        reportValue = report.report;
        if (
          Object.keys(reportValue).length !== 0 &&
          reportValue.report_values &&
          Object.keys(reportValue.report_values).length !== 0 &&
          Object.values(reportValue.report_values)
        ) {
          if (
            reportValue.report_values.farmUnit &&
            Object.keys(reportValue.report_values.farmUnit).length !== 0 &&
            Object.values(reportValue.report_values.farmUnit) &&
            reportValue.report_values.farmUnit.farmUnitCodeName
          ) {
            reportCodeName =
              reportValue.report_values.farmUnit.farmUnitCodeName;
          } else if (reportValue.report_values.reportName) {
            reportCodeName = reportValue.report_values.reportName;
          }
        }
      }
    }

    let date = "";
    if (
      report &&
      Object.keys(report).length !== 0 &&
      Object.values(report) &&
      reportValue &&
      Object.keys(reportValue).length !== 0 &&
      Object.values(reportValue) &&
      reportValue.published_date
    ) {
      date = convertDateToLocalDMMMMYYYYWithoutTimeStamp(
        reportValue.published_date
      );
    }
    let reportValues: any; //type is any from ExcelReportData interface
    if (
      report &&
      Object.keys(report).length !== 0 &&
      Object.values(report) &&
      reportValue &&
      Object.keys(reportValue).length !== 0 &&
      Object.values(reportValue) &&
      reportValue.report_values &&
      Object.keys(reportValue.report_values).length !== 0 &&
      Object.values(reportValue.report_values)
    ) {
      reportValues = reportValue.report_values;
    } else {
      reportValues = "-";
    }
    let farmUnit: any;
    if (
      report &&
      Object.keys(report).length !== 0 &&
      Object.values(report) &&
      reportValue &&
      Object.keys(reportValue).length !== 0 &&
      Object.values(reportValue) &&
      reportValue.report_values &&
      Object.keys(reportValue.report_values).length !== 0 &&
      Object.values(reportValue.report_values) &&
      reportValue.report_values.farmUnit &&
      Object.keys(reportValue.report_values.farmUnit).length !== 0 &&
      Object.values(reportValue.report_values.farmUnit)
    ) {
      farmUnit = reportValue.report_values.farmUnit;
    }
    excelSheetRawData.addRow({
      mockNormal: isMockReport ? "Mock" : "Normal",
      reportName: reportCodeName !== "" ? reportCodeName : "-",
      date: date !== "" ? date : "-",
      country:
        report.country &&
        Object.keys(report.country).length !== 0 &&
        Object.values(report.country)
          ? report.country.name
            ? report.country.name //!== null
              ? report.country.name
              : "-"
            : "-"
          : "-",
      region:
        report.region &&
        Object.keys(report.region).length !== 0 &&
        Object.values(report.region)
          ? report.region.name //!== null
            ? report.region.name === "N/A"
              ? "N/A"
              : report.region.name
            : "-"
          : "-",
      stateProvince:
        report.state &&
        Object.keys(report.state).length !== 0 &&
        Object.values(report.state)
          ? report.state.name
            ? report.state.name //!== null
              ? report.state.name
              : "-"
            : "-"
          : "-",
      client:
        report.client &&
        Object.keys(report.client).length !== 0 &&
        Object.values(report.client)
          ? report.client.name
            ? report.client.name //!== null
              ? report.client.name
              : "-"
            : "-"
          : "-",
      farm:
        report.farm &&
        Object.keys(report.farm).length !== 0 &&
        Object.values(report.farm)
          ? report.farm.name
            ? report.farm.name //!== null
              ? report.farm.name
              : "-"
            : "-"
          : "-",
      farmUnit:
        reportValue &&
        Object.keys(reportValue).length !== 0 &&
        Object.values(reportValue)
          ? reportValues !== "-"
            ? reportValues.farmUnit &&
              Object.keys(reportValues.farmUnit).length !== 0 &&
              Object.values(reportValues.farmUnit)
              ? reportValues.farmUnit.farmUnitCodeName
                ? farmUnit.farmUnitCodeName.split("-")[5]
                : "-"
              : "-"
            : "-"
          : "-",
      animalTypes:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport) &&
        summaryReport.animalType
          ? summaryReport.animalType.name
          : "-",
      weaningDate:
        reportValue &&
        Object.keys(reportValue).length !== 0 &&
        Object.values(reportValue)
          ? reportValues !== "-"
            ? reportValues.weaningDate //!== null
              ? convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                  reportValues.weaningDate
                )
              : "-"
            : "-"
          : "-",
      marketDate:
        reportValue &&
        Object.keys(reportValue).length !== 0 &&
        Object.values(reportValue)
          ? reportValues !== "-"
            ? reportValues.marketDate //!== null
              ? convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                  reportValues.marketDate
                )
              : "-"
            : "-"
          : "-",
      averageWeaningWeight:
        reportValue &&
        Object.keys(reportValue).length !== 0 &&
        Object.values(reportValue)
          ? reportValues !== "-"
            ? reportValues.averageWeaningWT ||
              reportValues.averageWeaningWT === 0
              ? reportValues.averageWeaningWT
              : "-"
            : "-"
          : "-",
      averageMarketWeight:
        reportValue &&
        Object.keys(reportValue).length !== 0 &&
        Object.values(reportValue)
          ? reportValues !== "-"
            ? reportValues.averageMarketWT || reportValues.averageMarketWT === 0
              ? reportValues.averageMarketWT
              : "-"
            : "-"
          : "-",
      adg:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.adg || summaryReport.adg === 0
            ? summaryReport.adg
            : "-"
          : "-",

      fcr:
        reportValue &&
        Object.keys(reportValue).length !== 0 &&
        Object.values(reportValue)
          ? reportValues !== "-"
            ? reportValues.fcr || reportValues.fcr === 0
              ? reportValues.fcr
              : "-"
            : "-"
          : "-",
      season:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport) &&
        summaryReport.season
          ? summaryReport.season.name
          : "-",

      marketAgeDays:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.marketAgeDays || summaryReport.marketAgeDays === 0
            ? summaryReport.marketAgeDays
            : "-"
          : "-",
      housingType:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport) &&
        summaryReport.housingType
          ? summaryReport.housingType.name
          : "-",
      flooringType:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport) &&
        summaryReport.flooringType
          ? summaryReport.flooringType.name
          : "-",
      operationType:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport) &&
        summaryReport.operationType
          ? summaryReport.operationType.name
          : "-",
      estMedicationCost:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.estMedicationCost ||
            summaryReport.estMedicationCost === 0
            ? summaryReport.estMedicationCost
            : "-"
          : "-",
      mHyoVaccine:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport) &&
        summaryReport.mHyoVaccine
          ? summaryReport.mHyoVaccine
          : "-",
      appVaccine:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport) &&
        summaryReport.appVaccine
          ? summaryReport.appVaccine
          : "-",
      prrsVaccine:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport) &&
        summaryReport.prrsVaccine
          ? summaryReport.prrsVaccine
          : "-",
      pcv2Vaccine:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport) &&
        summaryReport.pcv2Vaccine
          ? summaryReport.pcv2Vaccine
          : "-",
      genRemarks:
        reportValue &&
        Object.keys(reportValue).length !== 0 &&
        Object.values(reportValue)
          ? reportValues !== "-"
            ? reportValues.generalRemarks &&
              Object.keys(reportValues.generalRemarks).length !== 0 &&
              Object.values(reportValues.generalRemarks)
              ? reportValues.generalRemarks.comment
                ? reportValues.generalRemarks.comment
                : "-"
              : "-"
            : "-"
          : "-",
      totalLungsExamined:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.totalLungsObserved ||
            summaryReport.totalLungsObserved === 0
            ? summaryReport.totalLungsObserved
            : "-"
          : "-",
      bronchopneumonicLungs:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.bronchopneumoniaLungsPercentage ||
            summaryReport.bronchopneumoniaLungsPercentage === 0
            ? summaryReport.bronchopneumoniaLungsPercentage
            : "-"
          : "-",
      pneumoniaPrevalence:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.pneumoniaPrevalence ||
            summaryReport.pneumoniaPrevalence === 0
            ? summaryReport.pneumoniaPrevalence
            : "-"
          : "-",
      pneumoniaIndex:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.pneumoniaIndex || summaryReport.pneumoniaIndex === 0
            ? summaryReport.pneumoniaIndex
            : "-"
          : "-",
      pleurisyPrevalence:
        pleurisy &&
        Object.keys(pleurisy).length !== 0 &&
        Object.values(pleurisy)
          ? pleurisy.pleurisyPrevalence || pleurisy.pleurisyPrevalence === 0
            ? pleurisy.pleurisyPrevalence
            : "-"
          : "-",
      cranialPleurisy:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.pigsWithCranialPleurisyPercentage ||
            summaryReport.pigsWithCranialPleurisyPercentage === 0
            ? summaryReport.pigsWithCranialPleurisyPercentage
            : "-"
          : "-",
      dorsocaudalPleurisy:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.pigsWithDorsocaudalPleurisyPercentage ||
            summaryReport.pigsWithDorsocaudalPleurisyPercentage === 0
            ? summaryReport.pigsWithDorsocaudalPleurisyPercentage
            : "-"
          : "-",
      dorsalPleurisyIndex:
        pleurisy &&
        Object.keys(pleurisy).length !== 0 &&
        Object.values(pleurisy) &&
        pleurisy.appIndexDetails
          ? pleurisy.appIndexDetails.appIndex ||
            pleurisy.appIndexDetails.appIndex === 0
            ? pleurisy.appIndexDetails.appIndex
            : "-"
          : "-",
      abscess:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.totalPigsWithAbscess ||
            summaryReport.totalPigsWithAbscess === 0
            ? summaryReport.totalPigsWithAbscess
            : "-"
          : "-",
      scarringChronic:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.totalPigsWithScarring ||
            summaryReport.totalPigsWithScarring === 0
            ? summaryReport.totalPigsWithScarring
            : "-"
          : "-",
      pericarditis:
        summaryReport &&
        Object.keys(summaryReport).length !== 0 &&
        Object.values(summaryReport)
          ? summaryReport.totalPigsWithPericarditis ||
            summaryReport.totalPigsWithPericarditis === 0
            ? summaryReport.totalPigsWithPericarditis
            : "-"
          : "-",
    });
  });

  const buffer = await excelFileRawData.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";
  const blob = new Blob([buffer], { type: fileType });

  saveAs(
    blob,
    `${
      isMockReport ? "MOCK" : "NORMAL"
    }_SUMMARY_REPORT_${convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
      new Date()
    ).toString()}` + fileExtension
  );
}
