









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Paginate from 'vuejs-paginate';
import { DEFAULT_COUNT_PER_PAGE, DEFAULT_PAGE_NUMBER } from '@/utils/constants';

@Component({
  components: {
    Paginate,
  },
})
export default class Pagination extends Vue {
  @Prop({
    type: Number,
    default: 0,
  })
  totalEntries!: number;

  @Prop({
    type: Number,
    default: 10,
  })
  rowsPerPage!: number;

  @Prop({
    type: Number,
    default: 1,
  })
  pageNumber!: number;

  currentRowsPerPage = DEFAULT_COUNT_PER_PAGE;
  currentPageNumber = DEFAULT_PAGE_NUMBER;

  mounted() {
    this.currentRowsPerPage = this.rowsPerPage;
    this.currentPageNumber = this.pageNumber;
  }

  @Watch('pageNumber')
  updatePageNumber() {
    this.currentPageNumber = this.pageNumber;
  }

  @Watch('rowsPerPage')
  updateRowsPerPage() {
    this.currentRowsPerPage = this.rowsPerPage;
  }

  @Watch('currentRowsPerPage')
  @Watch('totalEntries')
  resetPageNumber() {
    // Anytime this value changes, we need to reset page number to 1, otherwise,
    this.currentPageNumber = 1;
    this.$emit('row-count-change', {
      rowsPerPage: this.currentRowsPerPage,
      offsetValue: this.startNumber <= 0 ? 0 : this.startNumber - 1,
    });
  }

  @Watch('currentPageNumber')
  emitPageNumber() {
    this.$emit('page-num-change', {
      pageNumber: this.currentPageNumber,
      offsetValue: this.startNumber <= 0 ? 0 : this.startNumber - 1,
    });
  }

  get maxPageCount() {
    return Math.ceil(this.totalEntries / this.currentRowsPerPage);
  }

  get startNumber() {
    if (this.totalEntries === 0) {
      return 0;
    }
    return (this.currentPageNumber - 1) * this.currentRowsPerPage + 1;
  }

  get endNumber() {
    const lastNumber = this.startNumber + this.currentRowsPerPage - 1;
    if (lastNumber > this.totalEntries) {
      return this.totalEntries;
    }
    return this.startNumber + this.currentRowsPerPage - 1;
  }
}
