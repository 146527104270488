import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import {
  Client,
  DropdownOptionRequest,
  DropdownOptionResponse,
} from '@/models/swine/client/client.model';
import { Farm } from '@/models/swine/farm/farm.model';
import { DEFAULT_SELECT_OPTION } from '@/utils/constants';
import { Region } from '@/models/swine/regions/region.model';
import { State } from '@/models/swine/states/state.model';
import { City } from '@/models/swine/cities/city.model';
import { generateUniqueId } from '@/utils/lodash';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'client.create',
  store,
})
class ClientCreate extends VuexModule {
  response: any | null = null;
  isLoading = false;
  error: any | null = null;
  clientDetails: Partial<Client> = {
    name: '',
    addressLine1: '',
    countryId: '',
    regionId: '',
    cityId: '',
    stateId: '',
    postcode: '',
    phoneNumber: '',
    clientCode: '',
    farms: [],
    species: '',
  };
  regionsList: DropdownOptionResponse | null = null;
  statesList: DropdownOptionResponse | null = null;
  citiesList: DropdownOptionResponse | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: any) {
    this.response = payload;
  }

  @Mutation
  updateName(payload: string) {
    this.clientDetails.name = payload;
  }

  @Mutation
  updateCountry(payload: string) {
    this.clientDetails.countryId = payload;
  }

  @Mutation
  updateRegion(payload: string) {
    this.clientDetails.regionId = payload;
  }

  @Mutation
  updateState(payload: string) {
    this.clientDetails.stateId = payload;
  }

  @Mutation
  updateCity(payload: string) {
    this.clientDetails.cityId = payload;
  }

  @Mutation
  updateStreetAddress(payload: string) {
    this.clientDetails.addressLine1 = payload;
  }

  @Mutation
  updatePhone(payload: string) {
    this.clientDetails.phoneNumber = payload;
  }

  @Mutation
  updatePostcode(payload: string) {
    this.clientDetails.postcode = payload;
  }

  @Mutation
  updateClientCode(payload: string) {
    this.clientDetails.clientCode = payload;
  }

  @Mutation
  updateFarm(payload: Farm[]) {
    this.clientDetails.farms = payload;
  }

  @Mutation
  setRegionsList(payload: DropdownOptionResponse) {
    this.regionsList = payload;
  }

  @Mutation
  setStatesList(payload: DropdownOptionResponse) {
    this.statesList = payload;
  }

  @Mutation
  setCitiesList(payload: DropdownOptionResponse) {
    this.citiesList = payload;
  }

  @Mutation
  setSpecies(payload: string) {
    this.clientDetails.species = payload;
  }

  @Mutation
  resetClientDetails() {
    this.clientDetails = {
      name: '',
      addressLine1: '',
      countryId: '',
      regionId: '',
      cityId: '',
      stateId: '',
      postcode: '',
      phoneNumber: '',
      clientCode: '',
      farms: [],
      species: ''
    };
  }

  @Action
  createClient() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    
    try {
      axios
        .post<any>(Endpoints.Clients, this.clientDetails)
        .then((response) => this.context.commit('setResponse', response));
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getRegions(request: DropdownOptionRequest) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get(Endpoints.Regions, {
          params: {
            country: request.value,
          },
        })
        .then((response) => {
          if (response.data) {
            const parsedData: DropdownOption[] = [];

            response.data.map((item: Region) => {
              parsedData.push({
                text: item.name,
                value: String(item.id),
                uniqueKey: generateUniqueId(`${item.name}_`),
              });
            });

            this.context.commit('setRegionsList', {
              code: request.code,
              list: [...DEFAULT_SELECT_OPTION, ...parsedData],
            });
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getStates(request: DropdownOptionRequest) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get(Endpoints.States, {
          params: {
            region: request.value,
          },
        })
        .then((response) => {
          if (response.data) {
            const parsedData: DropdownOption[] = [];

            response.data.map((item: State) => {
              parsedData.push({
                text: item.name,
                value: String(item.id),
                uniqueKey: generateUniqueId(`${item.name}_`),
              });
            });
            this.context.commit('setStatesList', {
              code: request.code,
              list: [...DEFAULT_SELECT_OPTION, ...parsedData],
            });
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getCities(request: DropdownOptionRequest) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get(Endpoints.Cities, {
          params: {
            state: request.value,
          },
        })
        .then((response) => {
          if (response.data) {
            const parsedData: DropdownOption[] = [];

            response.data.map((item: City) => {
              parsedData.push({
                text: item.name,
                value: String(item.id),
                uniqueKey: generateUniqueId(`${item.name}_`),
              });
            });

            this.context.commit('setCitiesList', {
              code: request.code,
              list: [...DEFAULT_SELECT_OPTION, ...parsedData],
            });
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getClientCode() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get<string>(`${Endpoints.Clients}/client_code`, {
          params: {
            country: this.clientDetails.countryId,
            region: this.clientDetails.regionId,
            state: this.clientDetails.stateId,
            species: this.clientDetails.species,
          },
        })
        .then((response) => {
          if (response.data) {
            this.context.commit('updateClientCode', response.data);
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  resetStore() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      this.resetClientDetails();
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ClientCreate);
