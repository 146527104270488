/* eslint-disable prettier/prettier */
import moment from 'moment';

export function addYearToDate(date: Date, yearIncrement: number) {
  return moment(date).add(yearIncrement, `year`).toDate();
}

export function convertDateToYYYYMMDDwithDashes(date: Date) {
  return moment(date).format(`YYYY-MM-DD`);
}

export function convertDateToYYYYMMDDwithDashesAndUTC(date: Date) {
  return moment(date).utc().format(`YYYY-MM-DD`);
}

export function convertDateToDDMMYYYYWithSlashes(date: Date | string) {
  return moment(date).format(`DD/MM/YYYY hh:mm A`);
}

export function convertDateToLocalDDMMYYYYWithSlashes(date: Date | string) {
  return moment(date).local().format(`DD/MM/YYYY hh:mm A`);
}

export function convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
  date: Date | string
) {
  return moment(date).local().format(`DD/MM/YYYY`);
}

export function getLastDayOfMonth(date: Date) {
  return moment(date).endOf('month').toDate();
}

export function getFirstDayOfMonth(date: Date) {
  return moment(date).startOf('month').toDate();
}

export function compareDates(startDate: Date, endDate: Date) {
  return moment(endDate).diff(startDate);
}

export function convertDateToLocalDMMMMYYYYWithoutTimeStamp(
  date: Date | string
) {
  return moment(date).local().format(`D MMMM, YYYY`);
}
