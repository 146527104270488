














import { Component, Emit, Vue } from 'vue-property-decorator';

@Component({})
export default class SearchBar extends Vue {
  searchField = '';

  @Emit('search')
  returnSearchField() {
    return this.searchField;
  }
}
